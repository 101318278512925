<template>
	<div class="find pa-3">
		
		<div class="mt-10 text-gray">
			등록된 아이디, 이메일 또는 연락처로 찾기
		</div>
		
		<div class="text-caption mt-2 text-gray">
			가입당시 등록한 아이디와 이메일 또는 연락처를 입력해주세요
		</div>
		
		<div class="mt-10">
		
			<v-text-field
				v-model="id"
				label="아이디"
				placeholder="등록된 아이디를 입력하세요"
				outlined
				color="success2"
				hide-detail
				type="email"
				:error="error ? true : false"
			></v-text-field>
			
		</div>
		
		<div class="mt-1 type2">
		
			<v-text-field
				v-model="phone"
				label="가입정보"
				placeholder="가입시 등록한 이메일 또는 연락처를 입력하세요"
				outlined
				color="success2"
				hide-detail
				type="email"
				:error="error ? true : false"
				:error-messages="error ? '입력하신 정보와 매칭되는 아이디가 없습니다' : ''"
			></v-text-field>
			
		</div>
		
		<v-btn 
			class="mt-5"
			color="success2" 
			@click="find"
			block
			:disabled="id && phone ? false : true"
		>
			확인
		</v-btn>
		
		<div class="text-center mt-3 text-caption color-gray">
			<router-link 
				:to="'/Login'" 
				class="text-decoration-none router-link"
			>로그인
			</router-link>
			|
			<router-link 
				:to="'/Help/FindId'" 
				class="text-decoration-none router-link"
			>아이디 찾기
			</router-link>
			|
			<router-link 
				:to="'/Help/Agree'" 
				class="text-decoration-none router-link"
			>회원가입
			</router-link>
		</div>
	</div>
</template>
<script>

	export default{
		name: 'FindId'
		,created: function(){
			this.$emit('setProgram', this.program, this.options)
		}
		,props: ['callBack']
		,data: function(){
			return{
				program: {
					title: '비밀번호 찾기'
					,not_navigation: true
					,prev: {
						to: '/Login'
					}
				}
				,options: {
				}
				,id: ''
				,email: ''
				,phone: ''
				,error: false
				,sample: {
					id: 'coehdus'
					,email: 'coehdus@naver.com'
					,phone: '01024892570'
				}
			}
		}
		,methods: {
			go: function(type){
				if(type == 'id'){
					this.$router.push('/Help/FindId')
				}else{
					this.$router.push('/Help/FindPw')
				}
			}
			,find: function(){
				this.$emit('axios',{
					request_type: 'post'
					, request_url: '/find/users/password'
					, request_params: {
						userId: this.id
						,keyword: this.phone
					}
					,callBack: 'result'
				})
			}
			,result: function(call){
				
				if(call.result){
					console.log('modal')
					this.error = false
					this.$emit('modal', {
						title: '아이디 찾기'
						,contents: call.items.message
						,to: '/Login'
					})
				}else{
					this.error = true
				}
			}
		}
		,watch: {
			callBack: function(call){

				if(call.name == 'result'){
					this.result(call)
				}
			}
		}
	}
</script>
<style>
	.find {}
	.find a {color: gray !important; padding: 0;}
	.find .v-text-field input {font-size: 0.8em; color: black;}
	.find .v-label {font-size: 1.2em !important; font-weight: bold; color: gray; }

	.find .v-input legend {width: 50px !important;}
	.find .type2 .v-input legend {width: 60px !important;}
	
	.v-text-field__slot {color: gray !important;}
</style>